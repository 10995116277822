<template>
<form @submit.prevent="">
    <div class="form-row">
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان ویجت" id="title" v-model="data.title"></v-text-field>
        <div class="col-12" v-if="!isMobile">
            <input type="text" id="title" placeholder="عنوان ویجت" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="data.title">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveSettings()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary"><i class="uil uil-save"></i> ذخیره</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مربوط به محتوای ویجت های تنظیمات (علاقه مندی ها یا نشان شده ها) است
export default {
    name: 'SettingsFavorite',
    components: {},
    props: ['data'],
    data: function () {
        return {
            isMobile:false,
        }
    },
    mounted() {
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد جهت ارسال اطلاعات داخل مودال به کلاس یا واسط پرنت می باشد(همون کال باک های معروف)
        saveSettings() {
            let data = this.$helpers.unbindObject(this.data);
            this.$parent.saveSettings(data);
        },
    },
}
</script>
